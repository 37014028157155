@import "./variables";

.custom-container {
  width: 100%;
  max-width: 1536px;
  padding: 0.5rem 2rem 0.5rem 2rem;
}

thead {
  background: #f3f3f3;
}

.h-100 {
  height: 100%;
}

.category-status-icon-bg {
  display: inline-block;
  color: white;
  margin-right: 10px;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  width: 2.5rem;
  border-radius: 999px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-h {
  display: flex;
  justify-content: center;
}

.center-v {
  display: flex;
  align-items: center;
}

.justify-right {
  display: flex;
  width: 100%;
  justify-content: right;
}

.icon-default {
  font-size: $font-size-lg*2;
  margin-right: 20px;
}

.icon-default-size {
  font-size: $font-size-lg*2;
}

.icon-small {
  font-size: $font-size-lg;
  margin-right: 10px;
}

.option-icon {
  font-size: $font-size-base*1.15;
}

.edit {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}

.click:hover {
  cursor: pointer;
}

.selectedIcon {
  border: 1px solid $admin-green;
  border-radius: $border-radius;
}

.reset {
  margin-left: 10px;
}

.admin-green {
  color: $admin-green;
}

.btn-admin-green {
  background: $admin-green;
  border-color: $green;
  color: $white;
}

.btn-admin-green:hover {
  background: lighten($admin-green, 15%) !important;
  border-color: $green !important;
  color: $white;
}
.btn-admin-green:active {
  background: lighten($admin-green, 15%) !important;
  border-color: $green !important;
}
.btn-admin-green:focus {
  background: lighten($admin-green, 15%) !important;
  border-color: $green !important;
}

.btn-admin-green:disabled {
  background: $admin-green !important;
  border-color: $admin-green !important;
}

.error {
  padding-top: 10px;
}

.alert-status {
  max-width: 800px;
}

.margin-top {
  margin-top: 50px;
}

.check {
  color: $green;
  padding-left: 10px;
  cursor: pointer;
}

.status-element {
  cursor: default;
  min-width: 12rem;
  &__hide {
    @media screen and (max-width: 1225px) {
      display: none;
    }
  }
}

.itemSelect {
  // Must overwrite defaults
  min-width: 150px !important;
  width: 60% !important;
  max-width: 250px !important;
}

.status-list {
  display: inline-block;
  color: white;
  margin-right: 10px;
  padding: 0.5rem;
  width: 15rem;
  border-radius: 0.25rem;
  .icon {
    margin-right: 0.5rem;
  }
}

.icon-status {
  padding: 4px 5px 8px 5px;
  margin: 10px;
  border-radius: 999px;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.column {
  display: flex;
  flex-direction: row;
}

.column-date {
  display: flex;
  flex-direction: column;
}


// custom css for angular file-uploader
.afu-select-btn {
  margin: 0 !important;
}

.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.icon-hover:hover {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.icon-hover {
  transition: opacity 0.2s;
}

.no-shrink {
  flex-shrink: 0;
}

.search-bar {
  max-width: 50rem;
}

.category-and-status-filter {
  margin-bottom: 2rem;
}

.form-input-error {
  border-color: $danger;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

// custom box shadow for some input fields
.form-input-admin-green:focus {
  outline: none;
  border-color: $admin-green !important;
  box-shadow: 0 0 0 .2rem rgba(38, 190, 144, .25) !important;
}

// overwrite default margin-top and max-width of modal
.modal-dialog {
  max-width: 750px !important;
  margin-top: 10vh !important;
}

.input-label {
  color: $secondary;
}

.input-label-left {
  display: flex;
  justify-content: flex-start !important;
}

.clear-icon {
  color: gray;
  transition: all 150ms;
  opacity: 1;
  &:hover {
    opacity: 0.7;
    transition: all 150ms;
    cursor: pointer;
  }
}

/*
.skeleton {
  position: relative;
  overflow: hidden;
  background: #ddd;

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%) skewX(-22.5deg);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .3), transparent);
    animation: loading 1.25s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
 */
