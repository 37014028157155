// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-hexCode and $modal-content-box-shadow-xs.


// custom colors
$admin-green: rgb(38, 190, 144) !default;
$admin-green-light: #dfebea;
$admin-green-dark: rgb(92, 123, 114);
$admin-pink: #d9679c;

$white:   #ffffff !default;
$gray-100: #F8F9FA !default;
$gray-600: #6C757D !default;
$gray-800: #343A40 !default;

$blue: #183b64 !default;
$indigo:  #6610f2 !default;
$purple: #7c4fb9 !default;
$pink: #de4e8f !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green: #23993c !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
      "blue":       $blue,
      "indigo":     $indigo,
      "purple":     $purple,
      "pink":       $pink,
      "red":        $red,
      "orange":     $orange,
      "yellow":     $yellow,
      "green":      $green,
      "teal":       $teal,
      "cyan":       $cyan,
      "white":      $white,
      "gray":       $gray-600,
      "gray-dark":  $gray-800
    ),
    $colors
);

$primary:       $admin-green !default;
$secondary:     $admin-green-dark !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;


$theme-colors: (
      "primary": $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
      "light":      $light,
      "dark":       $dark
    );

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

// all the defaults from bootstrap --> via importing it here the defaults are overridden to custom defaults
@import '../../node_modules/bootstrap/scss/bootstrap';
