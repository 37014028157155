@import 'universal';

.navbar {
  background-color: $admin-green;
  .navbar-brand {
    font-size: $font-size-lg * 1.25;
    padding-right: 1em;
    padding-left: 1em;
  }
  a {
    color: $white;
    opacity: 1;
    transition: all 0.3s;
  }
  a:hover {
    color: $white;
    opacity: 0.7;
    transition: all 0.3s;
  }
  li {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: $font-size-lg;
  }
  .navbar-toggler {
    color: $white;
  }
  .icon {
    margin-right: 0.5rem;
  }
  .extra-element {
    font-size: 20px;
    color: $white;
    opacity: 1;
    border: 1px solid $white;
    margin: 3px 10px 3px 3px;
    transition: all 0.3s;
  }
  .extra-element:hover {
    color: $white;
    opacity: 0.7;
    transition: all 0.3s;
  }

  .btn-logout {
    background-color: #6C757D;
    color: $white;
    transition: all 0.3s;
  }
  .btn-logout:hover {
    background-color: lighten(#6C757D, 7%);
    color: $white;
    transition: all 0.3s;
  }
}


