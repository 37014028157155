/* You can add global styles to this file, and also import other style files */
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@import 'scss/navbar';
@import "scss/universal";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

// @import '~ngx-toastr/toastr';
// @import '~ngx-toastr/toastr-bs4-alert';

/*
  global styles for toasts
 */
/* .toast-title, .toast-message {
  color: black !important;
}

.toast-close-button {
  color: black !important;
} */
